import { MarketOrder } from './dto/marketOrder';
// import { PagedResultDto } from './../dto/pagedResultDto';
import http from '../httpService';

class MarketOrderService {
  public async getMarketOrders(security: string, side: string): Promise<MarketOrder[]> {
    let result = await http.get(
      `/api/services/app/MarketData/getMarketOrders/?security=${security}&side=${side}`
    );

    return result.data.result;
  }
}

export default new MarketOrderService();
