import AppConsts from './appconst';
import Util from '../utils/utils';
import { MarketStat } from './../services/marketData/dto/marketStat';
import { ReportEventLog } from './../services/reportEventLogs/dto/reportEventLog';
import { MarketTrade } from './../services/marketTrade/dto/marketTrade';
import { MarketOrder } from './../services/marketOrder/dto/marketOrder';
import { News } from '../services/marketNews/dto/news';
import { StockOrder } from '../services/stockOrder/dto/stockOrder';
import storageService from '../services/storageService/storageService';

declare var abp: any;

class SignalRAspNetCoreHelper {
  initSignalR() {
    var encryptedAuthToken = abp.utils.getCookieValue(
      AppConsts.authorization.encrptedAuthTokenName
    );

    abp.signalr = {
      autoConnect: true,
      connect: undefined,
      hubs: '',
      qs:
        AppConsts.authorization.encrptedAuthTokenName +
        '=' +
        encodeURIComponent(encryptedAuthToken),
      remoteServiceBaseUrl: storageService.retrieve('remoteServiceBaseUrl'),
      url: 'signalr-tradebookhub',
    };

    Util.loadScript(storageService.retrieve('appBaseUrl') + '/dist/abp.signalr-client.js');

    abp.event.on('abp.signalr.connected', function () {
      const { AppEvents } = AppConsts;

      // Register for connect event

      //Market Stat
      abp.signalr.hubs.common.on(AppEvents.addMarketStatistics, (marketStat: MarketStat) => {
        abp.event.trigger(AppEvents.addMarketStatistics, marketStat);
      });
      abp.signalr.hubs.common.on(AppEvents.updateMarketStatsSession, (data: any) => {
        abp.event.trigger(AppEvents.updateMarketStatsSession, data);
      });

      //Market Order
      abp.signalr.hubs.common.on(AppEvents.addOrderBook, (orderbook: MarketOrder) => {
        abp.event.trigger(AppEvents.addOrderBook, orderbook);
      });
      // abp.signalr.hubs.common.on(
      //   AppEvents.addAllOrderBook,
      //   (orderbooks: marketOrder[], side: string) => {
      //     abp.event.trigger(AppEvents.addAllOrderBook, orderbooks, side);
      //   }
      // );
      abp.signalr.hubs.common.on(AppEvents.deleteOrderBook, (order: any) => {
        abp.event.trigger(AppEvents.deleteOrderBook, order.mdEntryID);
      });
      abp.signalr.hubs.common.on(AppEvents.updateOrderBook, (orderbook: MarketOrder) => {
        abp.event.trigger(AppEvents.updateOrderBook, orderbook);
      });
      abp.signalr.hubs.common.on(AppEvents.clearAllOrderBook, (security: string) => {
        abp.event.trigger(AppEvents.clearAllOrderBook, security);
      });

      //Market Trades
      abp.signalr.hubs.common.on(AppEvents.onMarketTrade, (marketTrade: MarketTrade) => {
        // console.log('market-trade', marketTrade);
        abp.event.trigger(AppEvents.onMarketTrade, marketTrade);
      });

      //Execution Report
      abp.signalr.hubs.common.on(AppEvents.onExecutionReport, (reportEventLog: ReportEventLog) => {
        abp.event.trigger(AppEvents.onExecutionReport, reportEventLog);
      });

      //News
      abp.signalr.hubs.common.on(AppEvents.onNews, (news: News) => {
        abp.event.trigger(AppEvents.onNews, news);
      });

      //NSE Connection
      abp.signalr.hubs.common.on(AppEvents.onNseConnection, (connected: boolean) => {
        abp.event.trigger(AppEvents.onNseConnection, connected);
      });

      //STOCK ORDER
      abp.signalr.hubs.common.on(AppEvents.addStockOrder, (stockOrder: StockOrder) => {
        abp.event.trigger(AppEvents.addStockOrder, stockOrder);
      });
      abp.signalr.hubs.common.on(AppEvents.updateStockOrder, (stockOrder: StockOrder) => {
        abp.event.trigger(AppEvents.updateStockOrder, stockOrder);
      });
      abp.signalr.hubs.common.on(AppEvents.replaceStockOrder, (stockOrder: StockOrder) => {
        abp.event.trigger(AppEvents.replaceStockOrder, stockOrder);
      });
    });
  }
}
export default new SignalRAspNetCoreHelper();
