import { SecurityListDto } from './../services/marketData/dto/securityList';
import cleanDeep from 'clean-deep';
import { makeAutoObservable } from 'mobx';
import * as pagedResultDto from '../services/dto/pagedResultDto';
import { MarketStat } from '../services/marketData/dto/marketStat';
import marketDataService from '../services/marketData/marketDataService';

class MarketDataStore {
  constructor() {
    makeAutoObservable(this);
  }

  marketStats!: pagedResultDto.PagedResultDto<MarketStat>;

  priceLimitRange!: string;

  securityLists!: pagedResultDto.PagedResultDto<SecurityListDto>;

  async getMarketStats(): Promise<pagedResultDto.PagedResultDto<MarketStat>> {
    let result = await marketDataService.getMarketStat();

    this.marketStats = result;

    return result;
  }

  async getSecurityLists(): Promise<pagedResultDto.PagedResultDto<SecurityListDto>> {
    let result = await marketDataService.getSecurityLists();

    this.securityLists = result;

    return result;
  }

  addMarketStatistics(marketStat: any): pagedResultDto.PagedResultDto<MarketStat> {
    if (this.marketStats) {
      let index = this.marketStats.items.findIndex(
        (obj: any) => obj.securityID === marketStat.securityID
      );

      if (index !== -1) {
        const marketStatClean = cleanDeep(marketStat);

        let updateRow = { ...this.marketStats.items[index], ...marketStatClean };

        this.marketStats.items[index] = updateRow;
      } else {
        this.marketStats.items.push(marketStat);
      }
    }

    return this.marketStats;
  }

  //TODO updateMarketStatsSession
  async updateMarketStatsSession(data: any) {
    console.log('updateMarketStatsSession', data);
  }

  async getPricelimitRange(security: string) {
    let result = await marketDataService.getPricelimitRange(security);

    this.priceLimitRange = result;
  }
}

export default MarketDataStore;
