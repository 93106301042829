import { IsTenantAvaibleInput } from './dto/isTenantAvailableInput';
import { RegisterInput } from './dto/registerInput';
import IsTenantAvaibleOutput from './dto/isTenantAvailableOutput';
import { RegisterOutput } from './dto/registerOutput';
import http from '../httpService';
import { RegistrationEmailVerificationInput } from './dto/registrationEmailVerificationInput';
import { PasswordResetInput } from './dto/passwordResetInput';
import { ChangePasswordInput } from './dto/changePasswordInput';
import { GenerateTwoFactorAuthTokenOutputDto } from './dto/generateTwoFactorAuthTokenOutputDto';

class AccountService {
  public async isTenantAvailable(
    isTenantAvaibleInput: IsTenantAvaibleInput
  ): Promise<IsTenantAvaibleOutput> {
    let result = await http.post(
      'api/services/app/Account/IsTenantAvailable',
      isTenantAvaibleInput
    );
    return result.data.result;
  }

  public async register(registerInput: RegisterInput): Promise<RegisterOutput> {
    let result = await http.post('api/services/app/Account/Register', registerInput);
    return result.data.result;
  }

  public async resendVerificationToken(accessToken: string): Promise<string> {
    let result = await http.get('api/services/app/Account/GetResendVerificationCode', {
      params: { accessToken },
    });
    return result.data.result;
  }

  public async verifyAccount(
    verificationInput: RegistrationEmailVerificationInput
  ): Promise<RegisterOutput> {
    let result = await http.post('api/services/app/Account/VerifyAccount', verificationInput);
    return result.data.result;
  }

  public async resetPassword(payload: PasswordResetInput): Promise<string> {
    let result = await http.post('api/services/app/Account/PasswordReset', payload);
    return result.data.result;
  }

  public async changePassword(payload: ChangePasswordInput): Promise<string> {
    let result = await http.post('api/services/app/Account/ChangePassword', payload);
    return result.data.result;
  }

  public async customerRegister(payload: any): Promise<string> {
    let result = await http.post('api/services/app/Customer/CustomerRegister', payload);
    return result.data.result;
  }

  public async generateTwoFactorAuthTokens(): Promise<GenerateTwoFactorAuthTokenOutputDto> {
    let result = await http.get('api/services/app/Account/GetTwoFactorAuthTokens');
    return result.data.result;
  }

  public async enableTwoFactorAuth(payload: any): Promise<string> {
    let result = await http.post('api/services/app/Account/EnableTwoFactorAuth', payload);
    return result.data.result;
  }

  public async disableTwoFactorAuth(): Promise<string> {
    let result = await http.post('api/services/app/Account/DisableTwoFactorAuth');
    return result.data.result;
  }
}

export default new AccountService();
