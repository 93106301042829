import * as reportEventLog from './../services/reportEventLogs/dto/reportEventLog';

import { makeAutoObservable } from 'mobx';
import reportEventLogService from '../services/reportEventLogs/reportEventLogService';

class ReportEventLogStore {
  constructor() {
    makeAutoObservable(this);
  }

  reportEventLog!: reportEventLog.ReportEventLog[];

  async getReportEventLogs() {
    let result = await reportEventLogService.getReportEventLogs();

    this.reportEventLog = result;
  }

  addReport(report: reportEventLog.ReportEventLog) {
    this.reportEventLog.splice(0, 0, report);
  }
}

export default ReportEventLogStore;
