export default class Stores {
  static AuthenticationStore: string = 'authenticationStore';

  static RoleStore: string = 'roleStore';

  static TenantStore: string = 'tenantStore';

  static UserStore: string = 'userStore';

  static SessionStore: string = 'sessionStore';

  static AccountStore: string = 'accountStore';

  static MarketDataStore: string = 'marketDataStore';

  static MarketOrderStore: string = 'marketOrderStore';

  static MarketTradeStore: string = 'marketTradeStore';

  static ReportEventLogStore: string = 'reportEventLogStore';

  static MarketNewsStore: string = 'marketNewsStore';

  static StockOrderDrawerStore: string = 'stockOrderDrawerStore';
}
