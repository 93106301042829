import { makeAutoObservable } from 'mobx';

import { GetCurrentLoginInformations } from '../services/session/dto/getCurrentLoginInformations';
import sessionService from '../services/session/sessionService';

class SessionStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentLogin: GetCurrentLoginInformations = new GetCurrentLoginInformations();

  async getCurrentLoginInformations() {
    let result = await sessionService.getCurrentLoginInformations();
    this.currentLogin = result;
  }
}

export default SessionStore;
