import type { ThemeConfig } from 'antd';

export const antdTheme: ThemeConfig = {
  token: {
    // controlHeightLG: 56,
    controlHeight: 35,
    fontFamily: 'CircularStd',
    colorPrimary: '#02a462',
  },
  components: {
    // Table: {
    //   headerBg: '#f0f1fa',
    //   rowHoverBg: '#c6c8df',
    //   cellFontSizeSM: 12,
    //   headerBorderRadius: 0,
    //},
  },
};
