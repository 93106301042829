export class StorageService {
  private storage: typeof localStorage;

  constructor() {
    this.storage = localStorage;
  }

  public retrieve(key: string): any {
    let item = this.storage.getItem(key);

    if (item && item !== 'undefined') {
      return JSON.parse(item);
    }

    return;
  }

  public store(key: string, value: any) {
    this.storage.setItem(key, JSON.stringify(value));
  }
}

export default new StorageService();
