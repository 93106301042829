import './globals';

import './index.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import Utils from './utils/utils';
import initializeStores from './stores/storeInitializer';
import registerServiceWorker from './registerServiceWorker';
import { stores, StoresProvider } from './contexts';

Utils.setLocalization();

const storesInjected = initializeStores();

ReactDOM.render(
  <Provider {...storesInjected}>
    <StoresProvider value={stores}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StoresProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement
);

registerServiceWorker();
