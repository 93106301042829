import { makeAutoObservable } from 'mobx';
import * as marketOrder from '../services/marketOrder/dto/marketOrder';
import marketOrderService from '../services/marketOrder/marketOrderService';
import * as _ from 'lodash';

class MarketOrderStore {
  constructor() {
    makeAutoObservable(this);
  }

  sellOrders: marketOrder.MarketOrder[] = [];

  buyOrders: marketOrder.MarketOrder[] = [];

  isBuyLoading: boolean = false;

  isSellLoading: boolean = false;

  selectedSecurityId: string = '';

  setSelectedSecurityId(selectedSecurityId: string) {
    this.selectedSecurityId = selectedSecurityId;
  }

  addOrderBook(orderbook: marketOrder.MarketOrder) {
    if (!this.selectedSecurityId || this.selectedSecurityId !== orderbook.securityID) {
      return;
    }

    if (orderbook.mdEntryType === '0') {
      let isBuyOrderExists = this.buyOrders.find((obj) => obj.mdEntryID === orderbook.mdEntryID);

      if (!isBuyOrderExists) {
        let orders = this.buyOrders;

        orders.push(orderbook);

        this.buyOrders = this.calculateTotalOrders(orders, 'desc');
      }
    } else if (orderbook.mdEntryType === '1') {
      let isSellOrderExists = this.sellOrders.find((obj) => obj.mdEntryID === orderbook.mdEntryID);

      if (!isSellOrderExists) {
        let orders = this.sellOrders;
        orders.push(orderbook);

        this.sellOrders = this.calculateTotalOrders(orders, 'asc');
      }
    }
  }

  async getAllOrderBook(security: string, side: string) {
    if (side === '0') {
      this.isBuyLoading = true;
    } else {
      this.isSellLoading = true;
    }

    let marketOrders = await marketOrderService.getMarketOrders(security, side);

    if (side === '0') {
      this.buyOrders = this.calculateTotalOrders(marketOrders, 'desc');
      this.isBuyLoading = false;
    } else {
      this.sellOrders = this.calculateTotalOrders(marketOrders, 'asc');
      this.isSellLoading = false;
    }
  }

  deleteOrderBook(mDEntryID: string) {
    let index1 = this.buyOrders.findIndex((obj) => obj.mdEntryID === mDEntryID);

    if (index1 > -1) {
      let orders = this.buyOrders;

      orders.splice(index1, 1);

      this.buyOrders = this.calculateTotalOrders(orders, 'desc');
      return;
    }

    let index2 = this.sellOrders.findIndex((obj) => obj.mdEntryID === mDEntryID);

    if (index2 > -1) {
      let orders = this.sellOrders;

      orders.splice(index2, 1);

      this.sellOrders = this.calculateTotalOrders(orders, 'asc');
    }
  }

  updateOrderBook(orderbook: marketOrder.MarketOrder) {
    if (!this.selectedSecurityId || this.selectedSecurityId !== orderbook.securityID) {
      return;
    }

    if (orderbook.mdEntryType === '0') {
      let index = this.buyOrders.findIndex((obj) => obj.mdEntryID === orderbook.mdEntryID);

      if (index > -1) {
        let orders = this.buyOrders;
        orders[index] = orderbook;

        this.buyOrders = this.calculateTotalOrders(orders, 'desc');
      }
    } else if (orderbook.mdEntryType === '1') {
      let index = this.sellOrders.findIndex((obj) => obj.mdEntryID === orderbook.mdEntryID);

      if (index > -1) {
        let orders = this.sellOrders;
        orders[index] = orderbook;

        this.sellOrders = this.calculateTotalOrders(orders, 'asc');
      }
    }
  }

  clearAllOrderBook(security: string) {
    if (!this.selectedSecurityId || this.selectedSecurityId !== security) {
      return;
    }

    this.buyOrders = [];
    this.sellOrders = [];
  }

  calculateTotalOrders = function (orders: marketOrder.MarketOrder[], sortDir: 'asc' | 'desc') {
    let qty = 0;

    let newOrders: any = [];

    let sortedOrders: any[] = orders.slice();

    sortedOrders = _.orderBy(sortedOrders, ['mdEntryPx', 'mdEntryTime'], [sortDir]);

    sortedOrders.forEach(function (item) {
      qty += item.mdEntrySize;
      item.totalQuantity = qty;
      newOrders.push(item);
    });

    return newOrders;
  };
}

export default MarketOrderStore;
