class TenantModel {
  tenancyName!: string;

  name!: string;

  isActive!: boolean;

  id!: number;
}

export default TenantModel;
