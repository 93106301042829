import { makeAutoObservable } from 'mobx';

import backOfficeService from '../services/backOffice/backOfficeService';
import { CustomerOutputDto } from '../services/backOffice/dto/customerOutputDto';
import { PortfolioHoldingOutputDto } from '../services/backOffice/dto/PortfolioHoldingOutputDto';
import { PortfolioValuationOutputDto } from '../services/backOffice/dto/PortfolioValuationOutputDto';
import { TransactionSummaryOutputDto } from '../services/stockOrder/dto/TransactionSummaryOutputDto';

class BackOfficeStore {
  constructor() {
    makeAutoObservable(this);
  }

  customers!: CustomerOutputDto[];

  public findCustomer = async (query: string): Promise<CustomerOutputDto[]> => {
    this.customers = await backOfficeService.findCustomer(query);

    return this.customers;
  };

  public getAccountBalance = async (cscsAcct: string): Promise<TransactionSummaryOutputDto> => {
    return backOfficeService.getAccountBalance(cscsAcct);
  };

  public getDashAccountPortfolioHolding = async (
    cscsAcct: string
  ): Promise<PortfolioHoldingOutputDto[]> => {
    return backOfficeService.getDashAccountPortfolioHolding(cscsAcct);
  };

  public getDashAccountPortfolioValuation = async (
    cscsAcct: string
  ): Promise<PortfolioValuationOutputDto[]> => {
    let data = await backOfficeService.getDashAccountPortfolioValuation(cscsAcct);

    data = data.map((item: any, index: any) => ({ id: index, ...item }));

    return data;
  };
}

export default BackOfficeStore;
