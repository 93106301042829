import { makeAutoObservable } from 'mobx';

import IsTenantAvaibleOutput from '../services/account/dto/isTenantAvailableOutput';
import accountService from '../services/account/accountService';
import { RegisterInput } from '../services/account/dto/registerInput';
import * as registerOutput from '../services/account/dto/registerOutput';
import { RegistrationEmailVerificationInput } from '../services/account/dto/registrationEmailVerificationInput';
import { PasswordResetInput } from '../services/account/dto/passwordResetInput';
import { ChangePasswordInput } from '../services/account/dto/changePasswordInput';

class AccountStore {
  constructor() {
    makeAutoObservable(this);
  }

  tenant: IsTenantAvaibleOutput = new IsTenantAvaibleOutput();

  registrationOutput!: registerOutput.RegisterOutput;

  public isTenantAvailable = async (tenancyName: string) => {
    this.tenant = await accountService.isTenantAvailable({ tenancyName: tenancyName });
  };

  public register = async (payload: RegisterInput) => {
    this.registrationOutput = await accountService.register(payload);

    return this.registrationOutput;
  };

  public resendVerificationToken = async (payload: string) => {
    const result = await accountService.resendVerificationToken(payload);

    return result;
  };

  public verifyAccount = async (payload: RegistrationEmailVerificationInput) => {
    const result = await accountService.verifyAccount(payload);

    return result;
  };

  public passwordReset = async (payload: PasswordResetInput) => {
    const result = await accountService.resetPassword(payload);

    return result;
  };

  public changePassword = async (payload: ChangePasswordInput) => {
    const result = await accountService.changePassword(payload);

    return result;
  };

  public customerRegister = async (payload: FormData) => {
    payload.set('accessToken', this.registrationOutput?.accessToken);
    const result = await accountService.customerRegister(payload);

    return result;
  };

  public setRegisterationOut = async (payload: registerOutput.RegisterOutput) => {
    this.registrationOutput = payload;
  };

  public generateTwoFactorAuthTokens = async () => {
    const result = await accountService.generateTwoFactorAuthTokens();

    return result;
  };

  public enableTwoFactorAuth = async (payload: any) => {
    const result = await accountService.enableTwoFactorAuth(payload);

    return result;
  };

  public disableTwoFactorAuth = async () => {
    const result = await accountService.disableTwoFactorAuth();

    return result;
  };
}

export default AccountStore;
