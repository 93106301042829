class RoleModel {
  name!: string;

  displayName!: string;

  description?: any;

  id!: number;
}

export default RoleModel;
