import { ReportEventLog } from './dto/reportEventLog';
import http from '../httpService';

class ReportEventLogService {
  public async getReportEventLogs(): Promise<ReportEventLog[]> {
    let result = await http.get('/api/services/app/ReportEventLog/GetReportEventLogs');

    return result.data.result;
  }
}

export default new ReportEventLogService();
