export const ROUTES = {
  userBase: '/user',
  userLogin: '/user/login',
  userCreateAccount: '/user/create-account',
  userForgotPassword: '/user/forgot-password',
  userResetPassword: '/user/reset-password/:eid?',
  landingPage: '/home',


  appBase: '/app',
  dashboard: '/app/dashboard',
  realtimeTrading: '/app/trading',
  accountSettings: '/app/account-settings',

  marketNews: '/app/marketData/marketNews',
  allPriceLists: '/app/marketData/all-pricelists',
  companyPriceLists: '/app/marketData/company-pricelists',
  gainersLossers: '/app/marketData/gainers-lossers',

  auditTrail: '/app/admin/reports/audit-trail',
  adminReportStockOrders: '/app/admin/reports/stock-orders',

  accountStatement: '/app/client-portal/account-statement',
  accountInflow: '/app/client-portal/account-inflow',
  accountOutflow: '/app/client-portal/account-outflow',
  portfolioHolding: '/app/client-portal/portfolio-holding',
  portfolioMovement: '/app/client-portal/portfolio-movement',
  portfolioValuation: '/app/client-portal/portfolio-valuation',
  purchaseContractNote: '/app/client-portal/purchase-contract-note',
  saleContractNote: '/app/client-portal/sale-contract-note',

  adminUsermanagment: '/app/admin/user-management',
  logOut: "/app/logout",
  exceptionPage: '/exception?type=401',
};
