import './App.css';

import { useEffect, useState } from 'react';

import Router from './components/Router';

import SignalRAspNetCoreHelper from './lib/signalRAspNetCoreHelper';
import { useStores } from './hooks/use-stores';

import 'react-base-table/styles.css';

import { ModalProvider } from './contexts';
import Loading from './components/Loading';
import abpUserConfigurationService from './services/abpUserConfigurationService';
import Utils from './utils/utils';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import { antdTheme } from './themes/antdTheme';

declare var abp: any;

const App = () => {
  const { sessionStore, marketDataStore, authenticationStore } = useStores();
  const [loading, setLoading] = useState(true);

  async function setUserConfiguration() {
    await abpUserConfigurationService.load();
    const userConfiguration = await abpUserConfigurationService.getAll();
    Utils.extend(true, abp, userConfiguration.data.result);
    abp.clock.provider = Utils.getCurrentClockProvider(
      userConfiguration.data.result.clock.provider
    );
    moment.locale(abp.localization.currentLanguage.name);

    if (abp.clock.provider.supportsMultipleTimezone) {
      //TODO
      //moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
    }
  }

  async function setUpLoginInformations() {
    await sessionStore!.getCurrentLoginInformations();
    if (
      !!sessionStore!.currentLogin.user &&
      (sessionStore!.currentLogin.application as any).features['SignalR.AspNetCore']
    ) {
      SignalRAspNetCoreHelper.initSignalR();
    }

    if (authenticationStore.isAuthenticated) {
      await marketDataStore.getSecurityLists().catch((err) => {
        console.log(err);
      });
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      await setUserConfiguration();

      await setUpLoginInformations();

      setLoading(false);
    })();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <ConfigProvider theme={antdTheme}>
      <ModalProvider value={'light'}>
        <Router />
      </ModalProvider>
    </ConfigProvider>
  );
};

export default App;
