import * as React from 'react';

import LoadableComponent from './../Loadable/index';
import { UserOutlined, ClockCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { ROUTES } from '../../constants/routes';

import dashIcon from '../../images/icons/dashIcon.svg';
import compPerfomanceIcon from '../../images/icons/compPerfomanceIcon.svg';
import marketNewsIcon from '../../images/icons/marketNewsIcon.svg';
import AppConsts from '../../lib/appconst';

export const userRouter: any = [
  {
    path: ROUTES.userBase,
    name: 'user',
    title: 'User',
    component: LoadableComponent(() => import('../../components/Layout/UserLayout')),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: ROUTES.userLogin,
    name: 'login',
    title: 'LogIn',
    exact: true,
    component: LoadableComponent(() => import('../../scenes/Login')),
    showInMenu: false,
  },
  {
    path: ROUTES.userCreateAccount,
    name: 'createAccount',
    title: 'Create Account',
    exact: true,
    component: LoadableComponent(() => import('../../scenes/CreateNewAccount')),
    showInMenu: false,
  },
  {
    path: ROUTES.userForgotPassword,
    name: 'forgotPassword',
    title: 'Forgot Password',
    exact: true,
    component: LoadableComponent(() => import('../../scenes/ForgotPassword')),
    showInMenu: false,
  },
  {
    path: ROUTES.userResetPassword,
    name: 'resetPassword',
    title: 'Reset Password',
    exact: true,
    component: LoadableComponent(() => import('../../scenes/ResetPassword')),
    showInMenu: false,
  },
];

export const appRouters: any = [
  {
    path: ROUTES.appBase,
    name: 'home',
    permission: '',
    title: 'Home',
    component: LoadableComponent(() => import('../../components/Layout/AppLayout')),
    isLayout: true,
    showInMenu: false,
  },
  {
    path: ROUTES.dashboard,
    name: 'dashboard',
    permission: '',
    title: 'Dashboard',
    exact: true,
    iconCustom: <img src={dashIcon} />,
    showInMenu: true,
    menuItem: true,
    component: LoadableComponent(() => import('../../scenes/Dashboard')),
  },
  {
    path: ROUTES.realtimeTrading,
    name: 'trading',
    permission: '',
    exact: true,
    title: 'Realtime Trading',
    icon: ClockCircleOutlined,
    showInMenu: true,
    menuItem: true,
    component: LoadableComponent(() => import('../../scenes/Trading')),
  },

  {
    path: '',
    name: 'marketData',
    permission: '',
    title: 'Market Data',
    showInMenu: true,
    itemGroup: true,
    itemGroupItems: [
      {
        name: 'companyPerformance',
        permission: '',
        subMenu: true,
        title: 'Performance',
        iconCustom: <img src={compPerfomanceIcon} />,
        showInMenu: true,
        subMenuItems: [
          {
            path: ROUTES.allPriceLists,
            name: 'pricelist',
            permission: '',
            title: 'All Price List',
            menuItem: true,
            iconCustom: <img src={compPerfomanceIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/AllPriceLists')),
          },
          {
            path: ROUTES.companyPriceLists,
            name: 'companyPriceList',
            permission: '',
            exact: true,
            menuItem: true,
            title: 'Company Price List',
            iconCustom: <img src={marketNewsIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/CompanyPriceLists')),
          },
          {
            path: ROUTES.gainersLossers,
            name: 'GainersLossers',
            permission: '',
            exact: true,
            menuItem: true,
            title: 'Gainer&sLossers',
            iconCustom: <img src={marketNewsIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/GainersLossers')),
          },
        ],
      },
      {
        path: ROUTES.marketNews,
        name: 'marketNews',
        permission: '',
        exact: true,
        title: 'Market News',
        iconCustom: <img src={marketNewsIcon} />,
        showInMenu: true,
        menuItem: true,
        component: LoadableComponent(() => import('../../scenes/MarketDataNews')),
      },
    ],
  },

  {
    path: '',
    name: 'clientPortal',
    permission: '',
    title: 'Client Portal',
    showInMenu: true,
    itemGroup: true,
    roles: [AppConsts.APP_ROLES.CUSTOMER],
    itemGroupItems: [
      {
        name: 'account',
        permission: '',
        subMenu: true,
        title: 'Account',
        iconCustom: <img src={compPerfomanceIcon} />,
        showInMenu: true,
        subMenuItems: [
          {
            path: ROUTES.accountStatement,
            name: 'accountStatement',
            permission: '',
            title: 'AccountStatement',
            menuItem: true,
            iconCustom: <img src={compPerfomanceIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/AccountStatement')),
          },
          {
            path: ROUTES.accountInflow,
            name: 'accountInflow',
            permission: '',
            exact: true,
            menuItem: true,
            title: 'Account Inflow',
            iconCustom: <img src={marketNewsIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/AccountInflow')),
          },
          {
            path: ROUTES.accountOutflow,
            name: 'accountOutflow',
            permission: '',
            exact: true,
            menuItem: true,
            title: 'Account Outflow',
            iconCustom: <img src={marketNewsIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/AccountOutflow')),
          },
        ],
      },
      {
        name: 'portfolio',
        permission: '',
        subMenu: true,
        title: 'Portfolio',
        iconCustom: <img src={compPerfomanceIcon} />,
        showInMenu: true,
        subMenuItems: [
          {
            path: ROUTES.portfolioHolding,
            name: 'portfolioHolding',
            permission: '',
            title: 'Portfolio Holding',
            menuItem: true,
            iconCustom: <img src={compPerfomanceIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/PortfolioHolding')),
          },
          {
            path: ROUTES.portfolioMovement,
            name: 'portfolioMovement',
            permission: '',
            exact: true,
            menuItem: true,
            title: 'Portfolio Movement',
            iconCustom: <img src={marketNewsIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/PortfolioMovement')),
          },
          {
            path: ROUTES.portfolioValuation,
            name: 'portfolioValuation',
            permission: '',
            exact: true,
            menuItem: true,
            title: 'Portfolio Valuation',
            iconCustom: <img src={marketNewsIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/PortfolioValuation')),
          },
        ],
      },
      {
        name: 'contractNote',
        permission: '',
        subMenu: true,
        title: 'Contract Note',
        iconCustom: <img src={compPerfomanceIcon} />,
        showInMenu: true,
        subMenuItems: [
          {
            path: ROUTES.purchaseContractNote,
            name: 'purchaseContractNote',
            permission: '',
            menuItem: true,
            title: 'Purchase Contract Note',
            iconCustom: <img src={compPerfomanceIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/PurchaseContractNote')),
          },
          {
            path: ROUTES.saleContractNote,
            name: 'saleContractNote',
            permission: '',
            exact: true,
            menuItem: true,
            title: 'Sale Contract Note',
            iconCustom: <img src={marketNewsIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/SaleContractNote')),
          },
        ],
      },
    ],
  },

  {
    path: '',
    name: 'administration',
    permission: 'VIEW_ADMINSETTING',
    title: 'Administration',
    showInMenu: true,
    itemGroup: true,
    itemGroupItems: [
      {
        name: 'reports',
        permission: '',
        title: 'Reports',
        subMenu: true,
        icon: ClockCircleOutlined,
        showInMenu: true,
        subMenuItems: [
          {
            path: ROUTES.auditTrail,
            name: 'auditTrail',
            permission: 'VIEW_AUDIT_TRAIL',
            exact: true,
            menuItem: true,
            title: 'Audit Trail',
            iconCustom: <img src={marketNewsIcon} />,
            showInMenu: true,
            component: LoadableComponent(() => import('../../scenes/Reports/AuditTrails')),
          },
          {
            path: ROUTES.adminReportStockOrders,
            name: 'ordersRpt',
            permission: 'VIEW_ALL_ORDERS',
            exact: true,
            title: 'Orders',
            menuItem: true,
            iconCustom: <img src={marketNewsIcon} />,
            showInMenu: true,
            component: LoadableComponent(
              () => import('../../scenes/Reports/AdminReportStockOrders')
            ),
          },
        ],
      },
      {
        path: ROUTES.adminUsermanagment,
        permission: 'Pages.Users',
        title: 'Users',
        name: 'user',
        icon: UserOutlined,
        showInMenu: true,
        menuItem: true,
        component: LoadableComponent(() => import('../../scenes/Users')),
      },
      // {
      //   path: '/roles',
      //   permission: 'Pages.Roles',
      //   title: 'Roles',
      //   name: 'role',
      //   icon: UserOutlined,
      //   showInMenu: true,
      //   component: LoadableComponent(() => import('../../scenes/Roles')),
      // },
      // {
      //   path: '/tenants',
      //   permission: 'Pages.Tenants',
      //   title: 'Tenants',
      //   name: 'tenant',
      //   icon: AppstoreOutlined,
      //   showInMenu: true,
      //   component: LoadableComponent(() => import('../../scenes/Tenants')),
      // },
    ],
  },
  {
    path: ROUTES.accountSettings,
    name: 'accountSettings',
    permission: '',
    title: 'Account Settings',
    exact: true,
    icon: SettingOutlined,
    showInMenu: true,
    menuItem: true,
    component: LoadableComponent(() => import('../../scenes/AccountSettings')),
  },

  {
    path: ROUTES.logOut,
    name: 'logout',
    showInMenu: false,
    permission: '',
    title: 'logout',
    menuItem: true,
    component: LoadableComponent(() => import('../../scenes/Logout')),
  },

  {
    path: '/exception?:type',
    permission: '',
    title: 'exception',
    name: 'exception',
    menuItem: true,
    showInMenu: false,
    component: LoadableComponent(() => import('../../scenes/Exception')),
  },
];

export const routers = [...userRouter, ...appRouters];
