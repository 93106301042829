import * as marketTrade_1 from './../services/marketTrade/dto/marketTrade';
import { makeAutoObservable } from 'mobx';
import marketTradeService from '../services/marketTrade/marketTradeService';

class MarketTradeStore {
  constructor() {
    makeAutoObservable(this);
  }

  marketTrades!: marketTrade_1.MarketTrade[];

  async getMarketTrades() {
    let result = await marketTradeService.getMarketTrades();

    this.marketTrades = result.items;
  }

  addMarketTrade(marketTrade: marketTrade_1.MarketTrade) {
    const result = this.marketTrades?.find((obj) => obj.mdEntryID === marketTrade.mdEntryID);

    if (!result) {
      this.marketTrades?.splice(0, 0, marketTrade);
    }
  }

  deleteAll() {
    this.marketTrades = [];
  }
}

export default MarketTradeStore;
