import { makeAutoObservable } from 'mobx';
import marketNewsService from '../services/marketNews/marketNewsService';
import * as pagedResultDto from '../services/dto/pagedResultDto';
import * as news from '../services/marketNews/dto/news';

class MarketNewsStore {
  constructor() {
    makeAutoObservable(this);
  }

  marketNews!: pagedResultDto.PagedResultDto<news.News>;

  async getMarketNews() {
    let result = await marketNewsService.getNews();

    this.marketNews = result;
  }

  addMarketNews(marketNews: news.News) {
    this.marketNews.items.splice(0, 0, marketNews);
  }
}

export default MarketNewsStore;
