import type { StockOrder } from './../services/stockOrder/dto/stockOrder';
import { makeAutoObservable } from 'mobx';
import stockOrderService from '../services/stockOrder/stockOrderService';
import { TradeCaptureReportInputDto } from '../services/stockOrder/dto/tradeCaptureReportInputDto';

class StockOrderStore {
  constructor() {
    makeAutoObservable(this);
  }

  stockOrders!: StockOrder[];

  async getStockOrders() {
    let result = await stockOrderService.getStockOrders();

    this.stockOrders = result.items;
  }

  addStockOrder(stockOrder: StockOrder) {
    const index = this.stockOrders.findIndex((x) => x.id === stockOrder.id);

    if (index === -1) {
      this.stockOrders.unshift(stockOrder);
    } else {
      this.stockOrders[index] = stockOrder;
    }
  }

  updateStockOrder(stockOrder: StockOrder) {
    const index = this.stockOrders.findIndex((x) => x.id === stockOrder.id);

    if (index === -1) {
      this.stockOrders.unshift(stockOrder);
    } else {
      this.stockOrders[index] = stockOrder;
    }
  }

  replaceStockOrder(stockOrder: StockOrder) {
    const index = this.stockOrders.findIndex((x) => x.id === stockOrder.id);

    if (index === -1) {
      this.stockOrders.unshift(stockOrder);
    } else {
      this.stockOrders[index] = stockOrder;
    }
  }

  async getOrderTransactionSummary(payload: any) {
    const result = stockOrderService.getOrderTransactionSummary(payload);

    return result;
  }

  async sendNewOrder(stockOrder: StockOrder) {
    const result = stockOrderService.sendNewOrder(stockOrder);

    return result;
  }

  async sendReplaceOrder(stockOrder: StockOrder) {
    const result = stockOrderService.sendReplaceOrder(stockOrder);

    return result;
  }

  async sendCancelOrderRequest(stockOrder: StockOrder) {
    const result = stockOrderService.sendCancelOrderRequest(stockOrder);

    return result;
  }

  async sendCrossDealOrder(stockOrder: TradeCaptureReportInputDto) {
    const result = stockOrderService.sendCrossDealOrder(stockOrder);

    return result;
  }

  getStockOrderByOrderId(orderId: String) {
    return this.stockOrders.find((x) => x.orderID === orderId);
  }
}

export default StockOrderStore;
