import { PagedResultDto } from './../dto/pagedResultDto';
import { News } from './dto/news';
import http from '../httpService';

class MarketNewsService {
  public async getNews(): Promise<PagedResultDto<News>> {
    let result = await http.get('/api/services/app/MarketData/GetNews');

    return result.data.result;
  }
}

export default new MarketNewsService();
